import React from 'react';
import ArrowRight from '../svg/ArrowRight';
import { ArrowButtonContent, Button } from './styles';

interface ArrowButtonProps {
  children?: React.ReactNode;
  onClick?: () => void;
  as?: 'button' | 'a';
  className?: string;
}

const ArrowButton = ({
  children,
  onClick,
  as,
  className,
  ...props
}: ArrowButtonProps) => {
  return (
    <Button
      round
      onClick={onClick}
      as={as}
      className={className}
      noText={!children}
      {...props}
    >
      <ArrowButtonContent noText={!children}>
        {children}
        <span className="icon">
          <ArrowRight />
        </span>
      </ArrowButtonContent>
    </Button>
  );
};

export default ArrowButton;
