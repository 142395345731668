import styled from 'styled-components';
import { SecondaryButton } from 'components/lib';

export const ArrowButtonContent = styled.span<{ noText: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 20px;
  width: ${({ noText }) => (noText ? '20px' : '100%')};
  .icon {
    transform: translateY(2px);
    z-index: 0;
  }
  svg {
    position: relative;
    z-index: 1;
    height: 20px;
    width: 20px;
    fill: ${({ noText, theme }) =>
      noText ? 'currentColor' : theme.colors.green};
    transition: fill 0.3s;
    margin-left: ${({ noText }) => (noText ? '0' : '7px')};

    rect {
      fill: ${({ noText, theme }) =>
        noText ? 'currentColor' : theme.colors.green};
    }
  }
`;

export const Button = styled(SecondaryButton)<{ noText?: boolean }>`
  // solves an issue with the mobile dropdown menus
  border: 2px solid ${({ theme }) => theme.colors.lightGreen};
  padding: ${({ noText }) => (noText ? '8px' : '8px 16px')};
  width: ${({ noText }) => (noText ? '36px' : 'auto')};
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
  .icon {
    rect,
    path {
      transition: fill 0.3s;
    }
  }

  &:hover {
    border: 2px solid ${({ theme }) => theme.colors.green};
    .icon {
      z-index: 1;
      rect,
      path {
        fill: ${({ theme }) => theme.colors.white};
      }
    }
  }
`;
