import { H3, Paragraph } from 'components/lib';
import React from 'react';
import NextLink from 'next/link';
import ArrowButton from '../ArrowButton/ArrowButton';
import { GeneralButtonContent } from './styles';

interface GeneralCardProps {
  title: string;
  description: String;
  Icon: React.FunctionComponent;
  href: string;
  size: string;
  buttonText?: string;
  className?: string;
  sizeLg?: string;
}
const GeneralCard = ({
  title,
  description,
  Icon,
  href,
  size,
  buttonText,
  className,
  sizeLg,
}: GeneralCardProps) => {
  return (
    <GeneralButtonContent
      size={size}
      className={className || ''}
      sizeLg={sizeLg}
    >
      <div className="card-icon">
        <Icon />
      </div>
      <div className="text">
        <NextLink href={href} legacyBehavior>
          <H3>{title}</H3>
        </NextLink>
        <Paragraph size="small">{description}</Paragraph>
      </div>
      <div className="button-wrapper">
        <NextLink href={href} legacyBehavior>
          <ArrowButton>{buttonText}</ArrowButton>
        </NextLink>
      </div>
    </GeneralButtonContent>
  );
};

export default GeneralCard;
