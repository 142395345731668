import styled from 'styled-components';

export const GeneralButtonContent = styled.div<{
  size: string;
  sizeLg?: string;
}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  border-radius: 16px;
  height: 248px;
  width: ${({ size }) => size};
  background: ${({ theme }) => theme.colors.white};
  z-index: 1;

  transition: box-shadow 0.2s ease-in-out;

  &:hover {
    box-shadow: ${({ theme }) => theme.shadows.default};
  }
  .card-icon {
    margin-bottom: 19px;
  }
  a {
    cursor: pointer;
  }
  .text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    h3,
    a {
      text-align: start;
      max-width: 268px;
      min-height: 75px;
      margin-bottom: 16px;
    }
    p {
      margin: 0;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: center;
      color: ${({ theme }) => theme.colors.navy};
      text-align: left;
    }
  }
  .button-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    width: ${({ sizeLg, size }) => sizeLg || size};
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    min-width: unset;
    width: 100%;
  }
`;
